"use client";

import { GetMenuIcon, menuList } from "@/data/menu-list";
import { allType } from "@/data/store";
import { Box, Button, Container, Group, Image } from "@mantine/core";
import dynamic from "next/dynamic";
import { useRouter } from "next/navigation";
const ToggleMenu = dynamic(() => import('./toggle-menu').then((module) => module.ToggleMenu), { ssr: true })

export function HeaderComponent({ isDevice, rootPath, cryptoSymbol, chainSymbol, fiatSymbol }: { isDevice: number, rootPath: string, cryptoSymbol: string, chainSymbol: string, fiatSymbol: String }) {

    const router = useRouter();

    return (
        <Box h={64} style={{ borderBottom: `1px solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4))` }} >
            <Container size="lg" h={64}>
                <Group gap={4} justify={"space-between"} align={"center"} h={"100%"}>
                    <Box component="a" href="/" onClick={(e) => {
                        e.preventDefault();
                        router.push("/");
                        if (rootPath != '') {
                        }
                    }}>
                        <Image h={32} w="auto" fit="contain" src="/logo.svg" alt="logo" darkHidden />
                        <Image h={32} w="auto" fit="contain" src="/dark.svg" alt="logo" lightHidden />
                    </Box>


                    <Group gap={"xs"} justify={"space-between"} w={{ base: "auto", sm: "auto" }} >
                        <Group gap={4}>
                            {
                                Object.values(menuList).map((r, i) => {
                                    if (r.headerVisible) {
                                        if (isDevice <= r.visibleFrom) {
                                            return (
                                                <Box
                                                    key={i}
                                                    component="a"
                                                    href={r.key === 'buy' || r.key === 'sell' ? chainSymbol ? `/p2p/${r.key}/${cryptoSymbol}-${chainSymbol}_${fiatSymbol}` : `/p2p/${r.key}/${cryptoSymbol ? cryptoSymbol : 'BTC'}_${fiatSymbol ? fiatSymbol : 'USD'}` : `/${r.key}`}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        router.push(r.key === 'buy' || r.key === 'sell' ? chainSymbol ? `/p2p/${r.key}/${cryptoSymbol}-${chainSymbol}_${fiatSymbol}` : `/p2p/${r.key}/${cryptoSymbol ? cryptoSymbol : 'BTC'}_${fiatSymbol ? fiatSymbol : 'USD'}` : `/${r.key}`);
                                                        if (r.key != rootPath) {
                                                        }
                                                    }}>
                                                    <Button
                                                        color={r.key === 'sell' ? "red" : ""}
                                                        //lightHidden
                                                        variant={rootPath === r.key ? "filled" : "subtle"}
                                                        leftSection={<GetMenuIcon menuKey={r.key} />}
                                                    >
                                                        {r.value}
                                                    </Button>
                                                </Box>
                                            )
                                        }
                                    }
                                })
                            }
                        </Group>
                        <ToggleMenu rootPath={rootPath} cryptoSymbol={cryptoSymbol} chainSymbol={chainSymbol} fiatSymbol={fiatSymbol} />
                    </Group>
                </Group>

            </Container>
        </Box>
    )
}