import { IconAd2, IconBasketDown, IconBasketUp, IconCoins, IconExchange, IconHistory, IconHome, IconSwitch3, IconUser, IconUserDollar, IconUserQuestion, IconWallet } from "@tabler/icons-react";

type menuType = {
    [key: string]: {
        key: string
        value: string
        full: string
        type: string
        headerVisible: boolean
        visibleFrom: number
    };
};

export const menuList: menuType = {
    "buy": { key: "buy", value: "Buy", full: 'Buy Crypto with FIAT', type: "service", headerVisible: true, visibleFrom: 2 },
    "sell": { key: "sell", value: "Sell", full: 'Sell Crypto for FIAT', type: "service", headerVisible: true, visibleFrom: 2 },
    "staking": { key: "staking", value: "Staking", full: 'Stake & Earn', type: "service", headerVisible: true, visibleFrom: 1 },
    "swap": { key: "swap", value: "Swap", full: 'Swap your Crypto', type: "service", headerVisible: true, visibleFrom: 1 },
    "bridge": { key: "bridge", value: "Bridge", full: 'Bridge to Chains', type: "service", headerVisible: true, visibleFrom: 1 },
    "wallet": { key: "wallet", value: "Wallet", full: 'Crypto Wallet', type: "service", headerVisible: true, visibleFrom: 1 },
    "account": { key: "account", value: "Account", full: 'Account Details', type: "account", headerVisible: false, visibleFrom: 1 },
    "myads": { key: "myads", value: "My Ads", full: 'Manage Ads', type: "account", headerVisible: false, visibleFrom: 1 },
    "history": { key: "history", value: "History", full: 'Manage Orders & History', type: "account", headerVisible: false, visibleFrom: 1 },
    "referral": { key: "referral", value: "Referral", full: 'Referral Details', type: "account", headerVisible: false, visibleFrom: 1 },
    "support": { key: "support", value: "Support", full: 'Support Ticket', type: "account", headerVisible: false, visibleFrom: 1 },
}


export function GetMenuIcon({ menuKey = '', size = 24 }: { menuKey?: string, size?: number }) {
    if (menuKey === '') {
        return <IconHome style={{ width: size, height: size }} />
    } else if (menuKey === 'buy') {
        return <IconBasketDown style={{ width: size, height: size }} />
    } else if (menuKey === 'sell') {
        return <IconBasketUp style={{ width: size, height: size }} />
    } else if (menuKey === 'staking') {
        return <IconCoins style={{ width: size, height: size }} />
    } else if (menuKey === 'swap') {
        return <IconExchange style={{ width: size, height: size }} />
    } else if (menuKey === 'bridge') {
        return <IconSwitch3 style={{ width: size, height: size }} />
    } else if (menuKey === 'wallet') {
        return <IconWallet style={{ width: size, height: size }} />
    } else if (menuKey === 'account') {
        return <IconUser style={{ width: size, height: size }} />
    } else if (menuKey === 'myads') {
        return <IconAd2 style={{ width: size, height: size }} />
    } else if (menuKey === 'history') {
        return <IconHistory style={{ width: size, height: size }} />
    } else if (menuKey === 'referral') {
        return <IconUserDollar style={{ width: size, height: size }} />
    } else if (menuKey === 'support') {
        return <IconUserQuestion style={{ width: size, height: size }} />
    }
}
